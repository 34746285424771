
<template>
<div>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row mb-2">
            <div class="col-sm-12 col-md-8">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex mr-2 align-items-center">
                  <b-form-select v-model="itemsPerPage" :options="pageOptions"></b-form-select>
                </label>
                <select @change="fetchItems()" style="width:100px;max-width:110px;" v-model="filterBySeen"  class="mr-2 d-inline mb-0 form-control">
                    <option disabled selected>-filter-</option>
                    <option value='0'>Unread</option>
                    <option value='1'>Read</option>
                    <option value='all'>All</option>
                </select>
                <b-dropdown variant="light" left>
                  <template slot="button-content">
                    <i class="mdi mdi-filter-variant"></i>
                  </template>
                    <b-dropdown-item-button @click="selectAllRows()"><i :class="isSelectAll? 'mdi mdi-playlist-remove' : 'mdi mdi-playlist-check'" ></i> {{isSelectAll? 'Invert selection' : 'Select All' }}</b-dropdown-item-button>
                  <b-dropdown-item-button @click="deleteItems()" ><i class="mdi mdi-delete-forever-outline"></i> Delete</b-dropdown-item-button>
                </b-dropdown>
              </div>
            </div>
            <div class="col-sm-12 col-md-4">
              <div class="">
                <label class="d-block">
                  <b-form-input v-model="searchQuery" @input="updateSearchQuery" type="search" placeholder="Search..." class="form-control"></b-form-input>
                </label>
              </div>
            </div>
          </div>
          <div class="table-responsive mb-0 loading-viewport">
            <is-loading v-if="isLoading" :box="true" />
            <b-table striped hover table-class="table font-sm table-centered w-100" thead-tr-class=""
              ref="selectableTable" :items="pageData.data" :fields="columns" responsive="sm">
              <template v-slot:cell(check)="data">
                  <div class="custom-control custom-checkbox text-center">
                      <input v-model="selected" type="checkbox" :value="data.item.id" class="custom-control-input" :id="`checkorder${data.item.id}`" />
                      <label class="custom-control-label" :for="`checkorder${data.item.id}`">{{data.item.id}}</label>
                  </div>
              </template>
              <template v-slot:cell(title)="data">
                <h5 class="m-0 d-inline-block cursor-pointer align-middle">
                  <span @click="navigateTo(data.item)" class="text-dark font-sm">{{ data.item.title }}</span>
                  <span v-if="!data.item.is_seen" class="badge badge-soft-info">new</span>
                </h5>
              </template>
              <template v-slot:cell(message)="data">
                <h5 class="m-0 d-inline-block cursor-pointer align-middle">
                  <span @click="navigateTo(data.item)" class="text-dark font-sm">{{ data.item.content }}</span>
                </h5>
              </template>
              <template v-slot:cell(date)="data">
                  <span class="cursor-pointer" @click="navigateTo(data.item)">{{data.item.created_at | date_ago}} </span>
              </template>
            </b-table>
          </div>
          <div class="row">
            <div class="col">
              <div class="paging_simple_numbers float-right">
                <b-pagination v-model="currentPage" class="pagination-rounded mb-0"
                  :total-rows="pageData.total" :limit="3" :per-page="itemsPerPage"></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import IsLoading from "@/components/IsLoading.vue"
import _ from 'lodash';

export default {
  components:{
    IsLoading
  },
  data() {
    return {
      isLoading: false,
      searchQuery: "",
      filterBySeen: "all",
      pageOptions: [10, 25, 50, 100],
      selected: [],
      isSelectAll: false,
      columns: [
          {
              key: "check",
              label: "ID"
          },
          {
            key: "title",
            sortable: true
          },
          {
            key: "Message",
            sortable: true
          },
          {
              key: "date",
              label: "Date",
              sortable: true
          },
      ]
    }
  },
  computed:{
    pageData() {
      return this.$store.state.notification.pageData
    },
    currentPage: {
      get() {
        return this.pageData.current_page
      },
      set(val) {
        if(!this.pageData.current_page || this.pageData.current_page == val) return
        this.fetchItems(val)
      }
    },
    itemsPerPage: {
      get() {
        return +this.pageData.per_page || 50
      },
      set(val) {
        this.fetchItems(1, val)
      }
    },
  },
  methods: {
    navigateTo(notif){
      console.log(notif)
      if(!notif.is_seen){
        this.$store.dispatch("notification/markAsRead", notif.id);
      }
      this.$router.push({path: notif.url});
    },
    selectAllRows() {
      this.isSelectAll = !this.isSelectAll
      if(this.isSelectAll){
        this.selected = this.pageData.data.map(o => o.id );
      }else{
        this.selected = []
      }
    },
    updateSearchQuery: _.debounce(function(string) {
      this.fetchItems()
    }, 2000),
    fetchItems(page, per_page = null){
      let payload = {page: page || 1, per_page: per_page || this.itemsPerPage }
      if(this.filterBySeen != 'all'){ payload.is_seen = this.filterBySeen }
      if(this.searchQuery){ payload.search = this.searchQuery }
      this.isLoading = true
      this.$store.dispatch("notification/fetchNotifications", payload)
      .then(response => this.isLoading = false)
    },
    deleteItems() {
      if(!this.selected.length){ this.alertError("You have not selected any item"); return}
      let text = `You are about to delete ${this.selected.length} notification${this.selected.length > 1 ? 's':'' }`;
      
      this.confirmDelete({
        text: text,
      }).then((result) => {
        if (result.value) {
          const formData = new FormData()
          formData.append('data', JSON.stringify({items: this.selected}))
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.post('/notifications/delete',formData)
          .then(response => {
            this.$store.dispatch("changeLoaderValue",false)
            if(response.data.success){
              this.fetchItems()
            }
          })
        }
      });
    },
  },
}
</script>

<style lang="scss">
.font-sm{
  font-size:13px;
}
</style>
